@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.price {
  @include ff(18px, 400, 24px);
  display: flex;
  align-items: center;
  white-space: nowrap;

  @media screen and (max-width: 1023px) {
    @include ff(17px, 400, 20px);
  }

  &Old {
    @include ff(14px, 500, 28px);
    color: var(--grey-darker-color);
    text-decoration: line-through;

    @media screen and (max-width: 1023px) {
      @include ff(14px, 500, 24px);
    }
  }

  &Wrap {
    display: flex;
    flex-direction: column;
  }

  &Comission {
    @include ff(18px, 400, 24px);

    @media screen and (max-width: 767px) {
      @include ff(14px, 500, 18px);
    }
  }
}
